import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppRoutingModule } from '../app-routing.module';

export const BASE_MODULES = [
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
  BrowserAnimationsModule,
  AppRoutingModule,
  FormsModule,
  ReactiveFormsModule,
];

export const CUSTOM_MODULES = [
  DeviceDetectorModule.forRoot(),
  NgxPageScrollCoreModule.forRoot({ duration: 1_500 }),
  NgxPageScrollModule,
];
