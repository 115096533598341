export const environment = {
  production: false,
  companyTitle: 'Coinflex',
  rootLink: '#',
  contacts: {
    supportEmail: 'support@coinflex.io',
    complianceEmail: 'compliance@coinflex.io',
    infoEmail: 'info@coinflex.io',
    partnersEmail: 'partners@coinflex.io',
    address:
      'Roosikrantsi tn 2 Kseklinna linnaosa,<br />Tallinn, Harju maakond',
    phone: '+3726028516',
    googleMapLink:
      // tslint:disable-next-line: max-line-length
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2029.0424546612217!2d24.740834416389557!3d59.432366481695105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692949e9c911d59%3A0x2b7cc2e521dbf224!2zUm9vc2lrcmFudHNpIDIsIDEwMTE5IFRhbGxpbm4sINCt0YHRgtC-0L3QuNGP!5e0!3m2!1sru!2sua!4v1557931245446!5m2!1sru!2sua',
  },
  apiUrl: '',
  licenceLinks: {
    walletService: '#',
    exchangeService: 'exchange.coinflex.io',
  },
  resourcesLinks: {
    terms: 'terms',
    policy: 'privacy-policy',
    cookies: 'cookies-policy',
    customer: 'acceptance-policy',
    merchant: 'merchant-acceptance',
    AML: 'aml-rules-virtual-currency-wallet-service'

  },
  socialLinks: {
    medium: '#',
    instagram: '#',
    twitter: '#',
    youtube: '#',
    telegram: '#',
    linkedin: '#',
  },
  walletLinks: {
    web: 'https://pay.elegro.eu/sign-in/',
    appStore: 'https://apps.apple.com/gb/app/elegro-crypto-fiat-wallet/id1443206753',
    playMarket: 'https://play.google.com/store/apps/details?id=paydeepp.elegro.io',
  },
  certifiedCoins: {
    web: 'https://exchange.coinflex.io',
    appStore: '#',
    playMarket: '#',
  },
};
