import { Component, Input } from '@angular/core';

import { productsLinks, ProductEnum } from 'src/app/core/models';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
@Component({
  selector: 'app-certified-features-block',
  templateUrl: './certified-features-block.component.html',
  styleUrls: ['./certified-features-block.component.scss'],
})
export class CertifiedFeaturesBlockComponent {
  @Input() public readonly certifiedLink: string =
    internalRoutes[InternalRouteEnum.CONTACT_US];
}
