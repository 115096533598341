import { Component, Input } from '@angular/core';

import { productsLinks, ProductEnum } from 'src/app/core/models';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
@Component({
  selector: 'app-certified-banner-block',
  templateUrl: './certified-banner-block.component.html',
  styleUrls: ['./certified-banner-block.component.scss'],
})
export class CertifiedBannerBlockComponent {
  @Input() public readonly certifiedLink: string =
  internalRoutes[InternalRouteEnum.CONTACT_US];
}
