import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { BASE_MODULES, CUSTOM_MODULES } from './modules';

@NgModule({
  declarations: [AppComponent, ...PAGES, ...COMPONENTS],
  imports: [BASE_MODULES, CUSTOM_MODULES],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
