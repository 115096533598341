import { Component } from '@angular/core';

import { cryptoCurrencies, ICryptoCurrency } from 'src/app/core/models';

@Component({
  selector: 'app-crypto-currencies-list',
  templateUrl: './crypto-currencies-list.component.html',
})
export class CryptoCurrenciesListComponent {
  public readonly cryptoCurrencies: ICryptoCurrency[] = cryptoCurrencies;
}
