import { Component, OnInit } from '@angular/core';
import {
  footerCompanyRoutes,
  IRoute,
  openedModalBodyClassToken,
} from 'src/app/core/models';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public readonly routes: IRoute[] = footerCompanyRoutes;
  constructor() { }

  ngOnInit() {
    console.log(this.routes);
  }

}
