import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {
  walletFeatures,
  IWalletFeature,
  ProductEnum,
} from 'src/app/core/models';
const enterTransition = transition(':enter', [
  style({
    opacity: 0
  }),
  animate('500ms ease-in', style({
    opacity: 1
  }))
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1
  }),
  animate('500ms ease-out', style({
    opacity: 0
  }))
])

const fadeIn = trigger('fadeIn', [
  enterTransition
]);

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-wallet-features-block',
  templateUrl: './wallet-features-block.component.html',
  styleUrls: ['./wallet-features-block.component.scss'],
  animations: [
    fadeIn,
    fadeOut
  ]
})
export class WalletFeaturesBlockComponent {
  public readonly walletFeatures: IWalletFeature[] = walletFeatures;
  public readonly walletToken: string = ProductEnum.WALLET;
  public readonly carousel: any = [ 
    {data:[0,2,4],show:true ,active:"active",type:'icons'} ,
    {data:[6,8,1],show:false ,active:"",type:'icons'},
    {data:[3,5,7],show:false ,active:"",type:'icons'}];
  public readonly mobCarousel: any = [ 
    {data:[],type:'phone',show:true ,active:"active",ind:'0'} ,
    {data:[0,2,4],type:'icons',show:false ,active:"",ind:'0'} ,
    {data:[],type:'phone',show:false ,active:"",ind:'1'} ,
    {data:[6,8,1],type:'icons',show:false ,active:"",ind:'1'},
    {data:[],type:'phone',show:false ,active:"",ind:'2'} ,
    {data:[3,5,7],type:'icons',show:false ,active:"",ind:'2'}
  ];
  public showMainContent: Boolean = true;
  public activeIndex: number = 0;
  next(index : string){
      if(parseInt(index) == this.activeIndex){
        if(this.activeIndex<this.carousel.length-1){
          this.activeIndex++;
        }else{
          this.activeIndex=0;
        }
        console.log('next');
        this.show(this.activeIndex.toString(),false);
      }else{
        this.show(index,false);
      }
  }  
  mobileNext(index : string){
    if(parseInt(index) == this.activeIndex){
      if(this.activeIndex<this.mobCarousel.length-1){
        this.activeIndex++;
      }else{
        this.activeIndex=0;
      }
      console.log('next');
      this.show(this.activeIndex.toString(),true);
    }else{
      this.show(index,true);
    }
}  
  show(index: string, mobile :boolean){
    let car = (mobile) ? this.mobCarousel : this.carousel ;
    for(let i in car){
      console.log('show');
      if(index == i){
        //console.log(i);
        car[i].show = true;
        car[i].active = "active";
      }else{
        car[i].show = false;
        car[i].active = "";
      }
    }
    this.activeIndex = parseInt(index);
  }
}
