import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { PageScrollService } from 'ngx-page-scroll-core';
import { internalRoutes, InternalRouteEnum, ExternalLinksEnum } from 'src/app/core/models';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
import { homeProductsBlockIdToken } from 'src/app/core/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public readonly btnSettings: IButtonSettings = {
    title: 'Place an order',
    callback: () => location.href = ExternalLinksEnum.EXCHANGE,
  };

  constructor(
    private readonly pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  private goToProducts() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: `#${homeProductsBlockIdToken}`,
    });
  }
}
