import { Component } from '@angular/core';

import { companyTitle } from 'src/app/core/models';

@Component({
  selector: 'app-home-points-block',
  templateUrl: './home-points-block.component.html',
  styleUrls: ['./home-points-block.component.scss'],
})
export class HomePointsBlockComponent {
  public readonly companyTitle: string = companyTitle;
}
