import { Component, OnInit, Input } from '@angular/core';

import {
  ProductEnum,
  playMarketLinks,
  playMarketAlts,
} from 'src/app/core/models';

@Component({
  selector: 'app-play-market-button',
  templateUrl: './play-market-button.component.html',
  styleUrls: ['./play-market-button.component.scss'],
})
export class PlayMarketButtonComponent implements OnInit {
  @Input() private product: ProductEnum;

  public link: string;
  public alt: string;

  ngOnInit() {
    this.initData();
  }

  private initData() {
    // this.link = playMarketLinks[this.product];
    this.link = 'https://play.google.com/store/apps/details?id=paydeepp.elegro.io';
    this.alt = playMarketAlts[this.product];
  }
}
