import { Component } from '@angular/core';

import { supportEmail } from 'src/app/core/models';

@Component({
  selector: 'app-support-block',
  templateUrl: './support-block.component.html',
  styleUrls: ['./support-block.component.scss'],
})
export class SupportBlockComponent {
  public readonly supportEmail: string = supportEmail;
}
