import { Component } from '@angular/core';

import { productsLinks, ProductEnum, companyTitle } from 'src/app/core/models';

@Component({
  selector: 'app-wallet-desktop-block',
  templateUrl: './wallet-desktop-block.component.html',
  styleUrls: ['./wallet-desktop-block.component.scss'],
})
export class WalletDesktopBlockComponent {
  public readonly walletWebLink: string = productsLinks[ProductEnum.WALLET].web;
  public readonly companyTitle: string = companyTitle;
}
