import { Component } from '@angular/core';

import { paymentMethods } from 'src/app/core/models';

@Component({
  selector: 'app-payments-icons',
  templateUrl: './payments-icons.component.html',
  styleUrls: ['./payments-icons.component.scss'],
})
export class PaymentsIconsComponent {
  public readonly paymentMethods = paymentMethods.reverse();
}
