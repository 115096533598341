import { Component } from '@angular/core';

import { ExternalLinksEnum } from 'src/app/core/models';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
@Component({
  selector: 'app-certified-coins',
  templateUrl: './certified-coins.component.html',
  styleUrls: ['./certified-coins.component.scss'],
})
export class CertifiedCoinsComponent {
  public readonly btnSettings: IButtonSettings = {
    title: 'Place an order',
    callback: () => location.href = ExternalLinksEnum.EXCHANGE,
  };
}
