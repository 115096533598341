import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() public readonly isInverse = false;
  @Input() public readonly title = 'Start now';
  @Input() public readonly alt: string;
  @Input() public readonly link: string;
  @Input() public readonly route: string;
}
