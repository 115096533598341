import { Component } from '@angular/core';

import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public readonly homeRoute = internalRoutes[InternalRouteEnum.HOME];
}
