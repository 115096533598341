import { Component } from '@angular/core';

import { IAccordionItem, cookies } from 'src/app/core/models';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent {
  public readonly cookies: IAccordionItem[] = cookies;
}
