import { Component } from '@angular/core';

import { IAccordionItem, merchant } from 'src/app/core/models';

@Component({
  selector: 'app-merchant-acceptance',
  templateUrl: './merchant-acceptance.component.html',
  styleUrls: ['./merchant-acceptance.component.scss'],
})
export class MerchantAcceptanceComponent {
  public readonly merchant: IAccordionItem[] = merchant;
}
