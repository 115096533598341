import { Component } from '@angular/core';

import { environment } from 'src/environments/environment';

export interface ISocialLinks {
  medium: string;
  instagram: string;
  twitter: string;
  youtube: string;
  telegram: string;
  linkedin: string;
}

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss'],
})
export class SocialLinksComponent {
  public readonly socialLinks: ISocialLinks = environment.socialLinks;
}
