import { Component } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-fiat-currencies-list',
  templateUrl: './fiat-currencies-list.component.html',
  styleUrls: ['./fiat-currencies-list.component.scss'],
})
export class FiatCurrenciesListComponent {
  public isShrinked: boolean;

  constructor(deviceService: DeviceDetectorService) {
    this.isShrinked = deviceService.isMobile();
  }

  public toggle() {
    this.isShrinked = !this.isShrinked;
  }
}
