import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { unsubscribe } from './core/helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public showSquare: boolean;
  private subs: Subscription[];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.subs = [];
  }

  ngOnInit() {
    this.observeRouting();
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  private observeRouting() {
    const sub: Subscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        try {
          this.showSquare = this.route.root.firstChild.snapshot.data.showSquare;
        } catch (error) {
          this.showSquare = false;
        }
      });
    this.subs.push(sub);
  }
}
