import { Component } from '@angular/core';

import { IAccordionItem, customer } from 'src/app/core/models';

@Component({
  selector: 'app-acceptance-policy',
  templateUrl: './acceptance-policy.component.html',
  styleUrls: ['./acceptance-policy.component.scss'],
})
export class AcceptancePolicyComponent {
  public readonly customer: IAccordionItem[] = customer;
}
