import { Component } from '@angular/core';

import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  public readonly homeLink = internalRoutes[InternalRouteEnum.HOME];
}
