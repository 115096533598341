import { Component } from '@angular/core';

import {
  footerCompanyRoutes,
  footerResouresLinks,
  footerResouresRoutes,
  IRoute,
  companyTitle,
} from 'src/app/core/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public readonly currentYear: string = String(new Date().getFullYear());
  public readonly footerCompanyRoutes: IRoute[] = footerCompanyRoutes;
  public readonly footerResouresRoutes: IRoute[] = footerResouresRoutes;
  public readonly footerResouresLinks: IRoute[] = footerResouresLinks;
  public readonly companyTitle: string = companyTitle;
}
