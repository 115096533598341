import { Component } from '@angular/core';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
import { companyTitle } from 'src/app/core/models';

@Component({
  selector: 'app-certified-questions',
  templateUrl: './certified-questions.component.html',
  styleUrls: ['./certified-questions.component.scss'],
})
export class CertifiedQuestionsComponent {
  public readonly contactUs = internalRoutes[InternalRouteEnum.CONTACT_US];
  //public readonly companyTitle: string = companyTitle.toLocaleLowerCase();
  public readonly companyTitle: string = 'coinflex';
  public readonly supportEmail: string = "info@info.com";
  public glowText: string = "flex";

  setGlowText(text: string){
    this.glowText = text;
  }
  cutGlow() {
    if(this.glowText ){
      
      return this.companyTitle.replace(this.glowText, "");
    }
    return;
  }
}
