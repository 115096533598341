import { Component } from '@angular/core';

import { privacyPolicies, IAccordionItem } from 'src/app/core/models';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  public readonly privacyPolicies: IAccordionItem[] = privacyPolicies;
}
