import { Component, Input } from '@angular/core';

import { IAccordionItem } from 'src/app/core/models';

@Component({
  selector: 'app-page-with-accordion',
  templateUrl: './page-with-accordion.component.html',
  styleUrls: ['./page-with-accordion.component.scss'],
})
export class PageWithAccordionComponent {
  @Input() public readonly title: string;
  @Input() public readonly subtitle: string;
  @Input() public readonly preview: string;
  @Input() public readonly items: IAccordionItem[];

  public toggleExpanding(index: number) {
    this.items[index].isExpanded = !this.items[index].isExpanded;
  }
}
