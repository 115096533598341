import { Component, OnInit, Input } from '@angular/core';

import { ProductEnum, appStoreLinks, appStoreAlts } from 'src/app/core/models';

@Component({
  selector: 'app-app-store-button',
  templateUrl: './app-store-button.component.html',
  styleUrls: ['./app-store-button.component.scss'],
})
export class AppStoreButtonComponent implements OnInit {
  @Input() private product: ProductEnum;

  public link: string;
  public alt: string;

  ngOnInit() {
    this.initData();
  }

  private initData() {
    // this.link = appStoreLinks[this.product];
    this.link = 'https://apps.apple.com/gb/app/elegro-crypto-fiat-wallet/id1443206753';
    this.alt = appStoreAlts[this.product];
  }
}
