import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  public form: FormGroup;
  public name: FormControl;
  public email: FormControl;
  public message: FormControl;
  public isLoading: boolean;

  public ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
    });
    this.isLoading = false;
    for (const control in this.form.controls) {
      if (control) {
        this[control] = this.form.controls[control];
      }
    }
  }

  public onSubmit() {
    this.isLoading = true;
    /* TODO */
    this.isLoading = false;
    this.form.reset();
  }
}
