import { Component } from '@angular/core';

import { IAccordionItem, terms } from 'src/app/core/models';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
  public readonly terms: IAccordionItem[] = terms;
}
