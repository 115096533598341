import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LogoComponent } from './logo/logo.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { SocialLinksComponent } from './social-links/social-links.component';
import { PaymentsIconsComponent } from './payments-icons/payments-icons.component';
import { ButtonComponent } from './button/button.component';
import { AppStoreButtonComponent } from './app-store-button/app-store-button.component';
import { PlayMarketButtonComponent } from './play-market-button/play-market-button.component';
import { SupportBlockComponent } from './support-block/support-block.component';
import { ComingSoonLayoutComponent } from './coming-soon-layout/coming-soon-layout.component';
import { TopBlockComponent } from './top-block/top-block.component';
import { PageWithAccordionComponent } from './page-with-accordion/page-with-accordion.component';

export const LAYOUT_COMPONENTS = [
  FooterComponent,
  HeaderComponent,
  LogoComponent,
  NavMenuComponent,
  SocialLinksComponent,
  PaymentsIconsComponent,
  ButtonComponent,
  AppStoreButtonComponent,
  PlayMarketButtonComponent,
  SupportBlockComponent,
  ComingSoonLayoutComponent,
  TopBlockComponent,
  PageWithAccordionComponent,
];
