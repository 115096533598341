import { Component } from '@angular/core';

import {
  internalRoutes,
  InternalRouteEnum,
  ProductEnum,
  homeProductsBlockIdToken,
  productsLinks,
  companyTitle,
} from 'src/app/core/models';

@Component({
  selector: 'app-home-products-block',
  templateUrl: './home-products-block.component.html',
  styleUrls: ['./home-products-block.component.scss'],
})
export class HomeProductsBlockComponent {
  public readonly blockId: string = homeProductsBlockIdToken;
  /* products routes */
  public readonly walletRoute: string =
    internalRoutes[InternalRouteEnum.WALLET];
  public readonly certifiedRoute: string =
    internalRoutes[InternalRouteEnum.CERTIFIED_COINS];
  /* products links */
  public readonly walletWebLink = internalRoutes[InternalRouteEnum.CONTACT_US];
  public readonly certifiedWebLink =
    internalRoutes[InternalRouteEnum.CONTACT_US];
  /* products tokens */
  public readonly walletToken = ProductEnum.WALLET;
  public readonly certifiedToken = ProductEnum.CERTIFIED_COINS;

  public readonly companyTitle: string = companyTitle;
}
