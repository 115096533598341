import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Subscription } from 'rxjs';

import { unsubscribe } from 'src/app/core/helpers';
import {
  headerRoutes,
  IRoute,
  openedModalBodyClassToken,
} from 'src/app/core/models';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  public readonly routes: IRoute[] = headerRoutes;

  public showMobileMenu: boolean;
  private subs: Subscription[];

  constructor(
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.showMobileMenu = false;
    this.subs = [];
  }

  ngOnInit() {
    this.observeRouting();
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  public toggleMobileMenu(value: boolean = !this.showMobileMenu) {
    this.showMobileMenu = value;
    const bodyClasses: DOMTokenList = this.document.body.classList;
    value
      ? bodyClasses.add(openedModalBodyClassToken)
      : bodyClasses.remove(openedModalBodyClassToken);
  }

  private observeRouting() {
    const sub: Subscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd && this.showMobileMenu) {
        this.toggleMobileMenu(false);
      }
    });
    this.subs.push(sub);
  }
}
