import { Component } from '@angular/core';

@Component({
  selector: 'app-supported-currencies-block',
  templateUrl: './supported-currencies-block.component.html',
  styleUrls: ['./supported-currencies-block.component.scss'],
})
export class SupportedCurrenciesBlockComponent {
  constructor() {}
}
