import { Component } from '@angular/core';

import { IAccordionItem, aml } from 'src/app/core/models';

@Component({
  selector: 'app-AML',
  templateUrl: './AML.component.html',
  styleUrls: ['./AML.component.scss'],
})
export class AMLComponent {
  public readonly aml: IAccordionItem[] = aml;
}
